<template>
  <div class="page-wrapper" id="article">
    <b-container fluid>
      <bo-page-title>
      </bo-page-title>
      <b-card-header>
        <b-row>
          <b-col lg="12">
            <div class="wrap_sts_total">
              <ul>
                <li><a href="">All <span>(8,060)</span></a> </li>
                <li><a href="">Pending <span>(44)</span></a> </li>
                <li><a href="">Approved <span>(8,016)</span></a> </li>
                <li><a href="">Spam <span>(1,150)</span></a> </li>
                <li><a href="">Trash <span>(11)</span></a> </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-header>
        <b-row>
          <b-col lg="auto">
            <b-button-group>
              <b-button v-b-tooltip.hover title="Delete"><i class="ti-trash"></i></b-button>
              <b-button v-b-tooltip.hover title="Spam"><i class="fas fa-exclamation-circle"></i></b-button>
              <b-button v-b-tooltip.hover title="Approved"><i class="fas fa-check"></i></b-button>
            </b-button-group>
          </b-col>
          <b-col lg="2">
            <b-form-group>
              <v-select placeholer="Select Status" v-model="selected_status" :options="statusFilters" />
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <b-form-group>
              <v-select placeholer="Select Category" v-model="selected_category" :options="category" />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <bo-search />
          </b-col>
        </b-row>
      </b-card-header>
      <bo-card use-table>
        <div class="table-responsive">
          <table class="table table-hover table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" class="custom-control-input" id="checkbox0" value="check">
                    <label class="custom-control-label" for="checkbox0">
                    </label>
                  </div>
                </th>
                <th scope="col">Author</th>
                <th scope="col">Comment</th>
                <th scope="col">In response to</th>
                <th scope="col">Submitted On</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" class="custom-control-input" id="checkbox0" value="check">
                    <label class="custom-control-label" for="checkbox0">
                    </label>
                  </div>
                </th>
                <td>
                  <div class="wrap_title_post">
                    <h3><a href="">vpcemohxo</a></h3>
                    <ul>
                      <li>194.233.69.237</li>
                      <li>itqqitx@outlook.com</li>
                    </ul>
                  </div>
                </td>
                <td>Here is a list of sites where you can post free ads...</td>
                <td>
                  <div class="wrap_title_post">
                    <h3><a href="">Review 10 Fitur Aplikasi Jenius BTPN Debit (Keunggulan & Kekurangan)</a></h3>
                    <ul>
                      <li><a href=""><i class=" icon-bubbles"></i> (3 comments)</a></li>
                    </ul>
                  </div>
                </td>
                <td>
                  2021/11/15 at 3:19 pm
                </td>
                <td>
                  <a href="#" v-on:click="isHidden = !isHidden" class="icon_action"><i class="fas fa-reply"></i></a>
                </td>
              </tr>
              <tr v-if="!isHidden">
                <td colspan="6">
                  <b-form-group label-for="articleTitle">
                    <template #label>Reply to Comment</template>
                    <b-form-textarea id="textarea" v-model="text" placeholder="Enter something..." rows="4"
                    max-rows="4">
                    </b-form-textarea>
                  </b-form-group>
                  <b-button variant="success" type="submit">Approve &amp; Reply</b-button>
                  <b-button variant="default" @click="cancelAddUpdate">Cancel
								</b-button>
                </td>
              </tr>
              <tr>
                <th>
                  <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" class="custom-control-input" id="checkbox0" value="check">
                    <label class="custom-control-label" for="checkbox0">
                    </label>
                  </div>
                </th>
                <td>
                  <div class="wrap_title_post">
                    <h3><a href="">Alakyupxh</a></h3>
                    <ul>
                      <li>194.233.69.237</li>
                      <li>tbtcaeyyvaae@gmail.com</li>
                    </ul>
                  </div>
                </td>
                <td>Resiko Investasi P2P Terlambat Dibayar (Koinworks &amp; Investree)</td>
                <td>
                  <div class="wrap_title_post">
                    <h3><a href="">Review 10 Fitur Aplikasi Jenius BTPN Debit (Keunggulan & Kekurangan)</a></h3>
                    <ul>
                      <li><a href=""><i class=" icon-bubbles"></i> (8 comments)</a></li>
                    </ul>
                  </div>
                </td>
                <td>
                  2021/11/15 at 3:19 pm
                </td>
                <td>
                  <a v-b-tooltip.hover title="Reply" href="#" class="icon_action"><i class="fas fa-reply"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <template #footer>
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" prev-text="Prev"
            next-text="Next" />
        </template>
      </bo-card>
    </b-container>
  </div>
</template>
<script>
  import GlobalVue from '@/libs/Global.vue'
  export default {
    name: 'Article',
    extends: GlobalVue,
    components: {},
    mounted() {
      this.loadSeoData()
      this.loadWorkData()
      this.loadHeroData()
      if (!this.isList) {
        if (!this.isAdd) {
          this.workRow = this.works[this.$route.params.slug - 1]
        } else {
          this.workRow = {
            status: 'Y'
          }
        }
      }
    },

    computed: {
      dataSrc() {
        return this.dummyData.ourWork
      },
    },

    methods: {
      loadSeoData() {
        let datas = this.dataSrc.seo
        this.seoRow = datas
      },
      loadHeroData() {
        let datas = this.dataSrc.heroImage
        this.heroData = datas
      },
      loadWorkData() {
        let datas = this.dataSrc.works
        datas.forEach(el => {
          this.works.push(el)
        })
      },
      handleWorkSubmit(key) {
        if (key) {
          this.works.splice(key, 1, this.workRow)
          this.confirmChanges('update', 'Case Study')
        } else {
          this.works.push(this.workRow)
          this.confirmChanges('add', 'Case Study')
        }
        this.$nextTick(() => this.$router.push({
          name: 'BoOurWork'
        }))
      },
      updateHero() {
        this.heroCollapse = true
        this.heroRow = this.heroData
      },
      closeHero() {
        this.heroCollapse = false
      },
    },
    data() {
      return {
        isHidden: false,
        heroData: {},
        heroRow: {},
        seoRow: {},
        works: [],
        workRow: {},
        rows: 30,
        perPage: 1,
        currentPage: 5,
        value: '',
        value2: '',
        heroCollapse: false,
        selected_status: {
          label: 'All Status',
          value: ''
        },
        status: [
          'Active',
          'Inactive',
        ],
        selected: 'A',
        ads_device: [{
            item: 'A',
            name: 'Desktop'
          },
          {
            item: 'B',
            name: 'Mobile'
          }
        ],
        selected_ads_size: 'Select Ads Size',
        ads_size: [
          'Select Ads Size',
          '300x250',
          '336x280',
          '728x90',
          '300x600',
          '320x100',
          '200x50',
          '320x20',
        ],
        selected_category: 'category 1',
        category: [
          'category 1',
          'category 2',
        ],

        selected_options: 'Publish',
        options: [
          'Draft',
          'Publish',
        ],
      }
    },
    watch: {
      $route() {
        if (!this.isList) {
          if (!this.isAdd) {
            this.workRow = this.works[this.$route.params.slug - 1]
          } else {
            this.workRow = {
              status: 'Y'
            }
          }
        }
      }
    }
  }
</script>